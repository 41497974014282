import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { state_Is_Authenticated } from "../../redux/authentication/CustomerAuthSlice";
import { setVendorId } from "../../redux/orders/ScannedCupSlice";
import Project_Loader from "../NavigationComponents/Project_Loader";

const CheckVendor = () => {
  const dispatch = useDispatch();
  const { vendorId } = useParams();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const isAuthenticated = useSelector(state_Is_Authenticated);

  useEffect(() => {
    const fetchVendorCredit = async () => {
      if (vendorId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/customer/account/check-credit/${vendorId}`
          );
          if (response.data) {
            if (response.data.message) {
              setMessage(response.data.message);
            } else if (response.data.userHaveCredit) {
              navigate("/qr/reader");
            } else {
              navigate("/packages");
              toast.error("You don't have enough credit to purchase.");
            }
          } else {
            setMessage("Something went wrong");
          }
        } catch (error) {
          console.error("Error fetching vendor credit", error);
          setMessage("Something went wrong");
        } finally {
          setLoading(false);
        }
      }
    };
    if (isAuthenticated) {
      fetchVendorCredit();
    } else {
      navigate("/auth");
    }
  }, [isAuthenticated, vendorId, navigate]);

  useEffect(() => {
    if (vendorId) {
      dispatch(setVendorId(vendorId));
    }
  }, [vendorId, dispatch]);

  return (
    <div className="check-vendor">
      {loading ? (
        <Project_Loader />
      ) : (
        <>
          <h1>Vendor Credit Check</h1>
          <p>{message}</p>
        </>
      )}
    </div>
  );
};

export default CheckVendor;
