import React, { useContext, useEffect, useState } from "react";
import { ImCross } from "react-icons/im";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ChiptNavBar from "../NavigationComponents/Chipt_Nav_Bar";

// Welcome windows modules
import ChiptCupScanGuideWindow from "../WelcomeComponents/Chipt_Cup_Scan_Guide";
import ChipInformationWindow from "../WelcomeComponents/Chipt_Information_Screen";
import ChiptScanGuideWindow from "../WelcomeComponents/Chipt_NFC_Scan_Guide";
import ChiptPaymentDoneWindow from "../WelcomeComponents/Chipt_Payment_Success";
import ChiptWelcomeWindow from "../WelcomeComponents/Chipt_Welcome";

// // Authentication modules
import ChiptAuth from "../AccountComponents/Authentication/ChiptAuth";
// import ForgotOTPVerification from "../AccountComponents/Authentication/Chipt_Account_Forgot_OTP_Verification";
// import OTPForgotPassword from "../AccountComponents/Authentication/Chipt_Account_Forgot_Password_OTP";
// import CustomerAccountOTPVerify from "../AccountComponents/Authentication/Chipt_Account_OTP_Verifcation";
// import ResetPassword from "../AccountComponents/Authentication/Chipt_Account_Password_Reset";
// import CustomerSignIn from "../AccountComponents/Authentication/Chipt_Account_Sign_In";
// import CustomerSignUp from "../AccountComponents/Authentication/Chipt_Account_Sign_Up";
// import CustomerPasswordChange from "../AccountComponents/Authentication/Chipt_Password_Update";

// Profile modules
import CustomerProfile from "../AccountComponents/Profile/Chipt_Profile_Account_Details";
import CustomerProfileUpdate from "../AccountComponents/Profile/Chipt_Profile_Account_Update";

// DashBoard modules
import QrScanner from "../../containers/QrScanner";
import NFCReader from "../NFCScanner/Chipt_NFC_Reader";
import PopUpNotification from "../NotificationComponents/PopUpNotification";
import CupOrderPreview from "../OrdersComponents/Chipt_Cup_Order_Preview";
import ChipVendorStore from "../VendorComponents/Chip_Vendor_Store";

// Cups
import ScannerCupDetails from "../CupsComponents/Chipt_Scanned_Cup_Details";
import CustomerAllOrderedCups from "../CupsComponents/Customer_All_Bought_Cups";

// Orders
import ChiptAllOrders from "../OrdersComponents/Chipt_All_Orders";
import ChiptSingleOrder from "../OrdersComponents/Chipt_Single_Order_Details";

// Transactions
import ChiptAllTransactions from "../TransactionComponents/Chipt_All_Transactions";
import ChiptSingleTransactions from "../TransactionComponents/Chipt_Single_Transactions";
import Payment_Screen from "../TransactionComponents/Payment_Screen";
import TXNStatusScreen from "../TransactionComponents/TransactionStatus";

// Return
import CustomerReturnCupPreview from "../OrdersComponents/ReturnCupPreview";

// Term and Conditon & Privacy Policy
import PrivacyPolicies from "../WelcomeComponents/PrivacyPolicies";
import RefundPolicy from "../WelcomeComponents/RefundPolicy";
import TermAndCondition from "../WelcomeComponents/TermAndCondition";

import DashBoard from "./Chipt_Home_Dashboard";

// Error Page
import ErrorPage from "./ErrorPage";

//
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ActionsContext } from "../../context/Context";
import { state_Is_Active } from "../../redux/authentication/AccountStatusSlice";
import {
  Call_Log_Out,
  state_Is_Authenticated,
} from "../../redux/authentication/CustomerAuthSlice";
import { Call_ReturnableCups } from "../../redux/orders/CustomerReturnableCupSlice";
import Authorize from "../authorize/Authorize";
import Nfc from "../nfc/Nfc";
import Payment from "../Payments/Payment";
import StripePayment from "../Payments/StripePayment";
import PaymentSuccess from "../PaymentSuccess/PaymentSuccess";
import PurchaseReturn from "../purchase-return/PurchaseReturn";
import Qr from "../qr/Qr";
import SelectVendor from "../select-vendor/SelectVendor";
import CheckVendor from "../VendorComponents/CheckVendor";
import PackagesMain from "../WalletComponents/PackagesMain";
import WalletMain from "../WalletComponents/WalletMain";

const Chipt_Entry_Point = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const topNavActive = useContext(ActionsContext);
  const isAuthenticated = useSelector(state_Is_Authenticated);
  const isAccountActive = useSelector(state_Is_Active);
  const [navActive, setNavActive] = useState(true);

  useEffect(() => {
    async function getProfile() {
      try {
        const response = await axios.get(
          `/api/v1/customer/account/information`
        );
        if (response.data.success) {
          dispatch({
            type: "customerAuth/Call_Sign_In/fulfilled",
            payload: response.data,
          });
        }
      } catch (err) {
        if (err && err.response && err.response.status === 401) {
          dispatch(Call_Log_Out());
          navigate("/auth");
        }
      }
    }

    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname === "/" && !isAuthenticated) {
      setNavActive(false);
    } else if (location.pathname === "/about") {
      setNavActive(false);
    } else if (location.pathname === "/cup/scan") {
      setNavActive(false);
    } else if (location.pathname === "/scan/guide") {
      setNavActive(false);
    } else if (location.pathname === "/payment/guide") {
      setNavActive(false);
    } else {
      setNavActive(true);
    }
  }, [
    location,
    navActive,
    isAccountActive,
    dispatch,
    topNavActive,
    isAuthenticated,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(Call_ReturnableCups()).then((data) => {
        if (data.payload) {
          if (data.payload.success) {
            topNavActive.isPopUp(true);
          }
        }
      });
    }
  }, [dispatch, isAuthenticated, topNavActive]);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     if (typeof isAccountActive === "boolean" && !isAccountActive) {
  //       navigate("/otpverify");
  //     }
  //   }
  // }, [isAuthenticated, isAccountActive, location.pathname, navigate]);

  return (
    <div className="select-none">
      {isAuthenticated && topNavActive.isPopUp ? (
        <div className="w-[100%] h-screen bg-white overflow-auto">
          <div className="w-[60%] sm:w-[70%] h-[3rem] ml-[20%] float-left flex items-center justify-center">
            <p className="text-2xl font-bold text-primaryNavy text-center">
              Alerts
            </p>
          </div>
          <div className="w-[20%] sm:w-[10%] h-[3rem] float-left flex items-center justify-center bg-white">
            <button
              className="p-2 bg-black sm:text-sm text-white rounded-full"
              onClick={() => {
                topNavActive.isPopUp(false);
              }}
            >
              <ImCross />
            </button>
          </div>
          <PopUpNotification />
        </div>
      ) : (
        <></>
      )}

      {navActive && !topNavActive.isPopUp ? (
        <>
          <ChiptNavBar />
        </>
      ) : (
        <></>
      )}
      {topNavActive.notiNavBar ||
      topNavActive.activeNavBar ||
      topNavActive.isPopUp ? (
        <></>
      ) : (
        <div className="sm:w-[100%] sm:mx-0 md:w-[70%] md:mx-[15%] lg:w-[50%] lg:mx-[25%] h-[88vh] ">
          {isAuthenticated ? (
            <>
              <Routes>
                <Route path="/" element={<DashBoard />} exact />
                <Route path="/home" element={<DashBoard />} exact />
                <Route
                  path="/about"
                  element={<ChipInformationWindow />}
                  exact
                />
                <Route path="/payment" element={<Payment />} exact />
                <Route
                  path="/stripe-payment"
                  element={<StripePayment />}
                  exact
                />
                <Route
                  path="/purchase-return"
                  element={<PurchaseReturn />}
                  exact
                />
                <Route
                  path="/cup/scan"
                  element={<ChiptCupScanGuideWindow />}
                  exact
                />

                <Route
                  path="/payment-success"
                  element={<PaymentSuccess />}
                  exact
                />

                <Route
                  path="/scan/guide"
                  element={<ChiptScanGuideWindow />}
                  exact
                />
                <Route
                  path="/payment/guide"
                  element={<ChiptPaymentDoneWindow />}
                  exact
                />
                <Route
                  path="/vendor/store/:id"
                  element={<ChipVendorStore />}
                  exact
                />
                <Route
                  path="/scanned/cup/:mid"
                  element={<ScannerCupDetails />}
                  exact
                />
                <Route
                  path="/cup/order/preview/:mid/:cid"
                  element={<CupOrderPreview />}
                  exactf
                />
                <Route path="/nfc/reader" element={<NFCReader />} exact />
                <Route path="/qr/reader" element={<QrScanner />} exact />
                <Route
                  path="/check/vendor/:vendorId"
                  element={<CheckVendor />}
                  exact
                />
                {/* <Route
                  path="/forgot/password"
                  element={<OTPForgotPassword />}
                  exact
                />
                <Route
                  path="/forgot/otp/verify"
                  element={<ForgotOTPVerification />}
                  exact
                />
                <Route
                  path="/reset/password"
                  element={<ResetPassword />}
                  exact
                />
                <Route path="/signin" element={<CustomerSignIn />} exact />
                <Route path="/signup" element={<CustomerSignUp />} exact />
                <Route
                  path="/otpverify"
                  element={<CustomerAccountOTPVerify />}
                  exact
                />
                <Route
                  path="/password/change"
                  element={<CustomerPasswordChange />}
                  exact
                /> */}
                <Route path="/profile" element={<CustomerProfile />} exact />
                <Route
                  path="/profile/update"
                  element={<CustomerProfileUpdate />}
                  exact
                />
                <Route path="/all/orders" element={<ChiptAllOrders />} exact />
                <Route
                  path="/single/order/:id"
                  element={<ChiptSingleOrder />}
                  exact
                />
                <Route
                  path="/all/transactions"
                  element={<ChiptAllTransactions />}
                  exact
                />
                <Route
                  path="/single/transaction/:id"
                  element={<ChiptSingleTransactions />}
                  exact
                />
                <Route
                  path="/termcondition"
                  element={<TermAndCondition />}
                  exact
                />
                <Route
                  path="/privacypolicy"
                  element={<PrivacyPolicies />}
                  exact
                />
                <Route path="/nfc" element={<Nfc />} exact />
                <Route path="/qr" element={<Qr />} exact />
                <Route path="/authorize" element={<Authorize />} exact />

                <Route path="/select-vendor" element={<SelectVendor />} exact />

                <Route path="/refundpolicy" element={<RefundPolicy />} exact />
                <Route
                  path="/order/payment"
                  element={<Payment_Screen />}
                  exact
                />
                <Route
                  path="/all/ordered/cups"
                  element={<CustomerAllOrderedCups />}
                  exact
                />
                <Route
                  path="/return/cup/preview"
                  element={<CustomerReturnCupPreview />}
                  exact
                />
                <Route
                  path="/payment/status"
                  element={<TXNStatusScreen />}
                  exact
                />
                <Route path="/wallet" element={<WalletMain />} />
                <Route path="/packages" element={<PackagesMain />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </>
          ) : (
            <>
              <Routes>
                {/* <Route path="/home" element={<DashBoard />} exact /> */}

                {/* <Route
                  path="/cup/scan"
                  element={<ChiptCupScanGuideWindow />}
                  exact
                />
                <Route
                  path="/scan/guide"
                  element={<ChiptScanGuideWindow />}
                  exact
                />
                <Route
                  path="/payment/guide"
                  element={<ChiptPaymentDoneWindow />}
                  exact
                />
                <Route
                  path="/vendor/store/:id"
                  element={<ChipVendorStore />}
                  exact
                />
                <Route
                  path="/scanned/cup/:mid/:cid"
                  element={<ScannerCupDetails />}
                  exact
                />
                <Route
                  path="/cup/order/preview/:mid/:cid"
                  element={<CupOrderPreview />}
                  exact
                />
                <Route path="/nfc/reader" element={<NFCReader />} exact />

                <Route path="/qr/reader" element={<QrScanner />} exact /> */}
                {/* <Route
                  path="/forgot/password"
                  element={<OTPForgotPassword />}
                  exact
                  />
                  <Route
                  path="/forgot/otp/verify"
                  element={<ForgotOTPVerification />}
                  exact
                  />
                  <Route
                  path="/reset/password"
                  element={<ResetPassword />}
                  exact
                  />
                  <Route path="/signin" element={<CustomerSignIn />} exact />
                  <Route path="/signup" element={<CustomerSignUp />} exact />
                  <Route
                  path="/otpverify"
                  element={<CustomerAccountOTPVerify />}
                  exact
                  />
                  <Route
                  path="/password/change"
                  element={<CustomerPasswordChange />}
                  exact
                  /> */}
                {/* <Route path="/profile" element={<CustomerProfile />} exact />
                <Route
                path="/profile/update"
                element={<CustomerProfileUpdate />}
                exact
                />
                <Route path="/all/orders" element={<ChiptAllOrders />} exact />
                <Route
                path="/single/order/:id"
                element={<ChiptSingleOrder />}
                exact
                />
                <Route
                path="/all/transactions"
                  element={<ChiptAllTransactions />}
                  exact
                  />
                  <Route
                  path="/single/transaction/:id"
                  element={<ChiptSingleTransactions />}
                  exact
                  /> */}
                <Route path="/" element={<ChiptWelcomeWindow />} exact />
                <Route
                  path="/about"
                  element={<ChipInformationWindow />}
                  exact
                />
                <Route path="/auth" element={<ChiptAuth />} exact />
                <Route
                  path="/termcondition"
                  element={<TermAndCondition />}
                  exact
                />
                <Route
                  path="/privacypolicy"
                  element={<PrivacyPolicies />}
                  exact
                />
                <Route
                  path="/check/vendor/:vendorId"
                  element={<CheckVendor />}
                  exact
                />
                <Route path="/refundpolicy" element={<RefundPolicy />} exact />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Chipt_Entry_Point;
