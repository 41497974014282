import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import random_logo from "../asset/admin.jpg";
import Loader from "../NavigationComponents/Chipt_Loader";

const Chipt_Show_Vendor = ({ vendorsData }) => {
  const dispatch = useDispatch();

  const vendorStoreDetails = (location) => {
    if (location && Array.isArray(location) && location.length === 2) {
      const [longitude, latitude] = location;
      const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
      window.location.href = googleMapsUrl;
    }
  };

  useEffect(() => {}, [dispatch]);

  return (
    <>
      <div className="w-full h-auto mx-auto flex p-2  overflow-x-auto gap-3">
        {vendorsData ? (
          <>
            {vendorsData.map((data) => {
              return (
                <div
                  key={data._id}
                  onClick={() => vendorStoreDetails(data.location)}
                  className="overflow-hidden w-[250px] shrink-0   mx-auto rounded-xl"
                >
                  <div className=" w-full shrink-0">
                    <div className="h-[120px] bg-[#EAF2FF] flex items-center justify-center overflow-hidden">
                      <img
                        className="w-full h-full object-cover"
                        src={
                          data.profilePicture && data.profilePicture.url
                            ? data.profilePicture.url
                            : random_logo
                        }
                        alt="vendor_store"
                      />
                    </div>
                    <div className="w-[100%] p-4">
                      <p className="w-[100%]  text-sm font-extrabold text-start text-ellipsis overflow-hidden whitespace-nowrap ">
                        {data?.name
                          ? data.name.charAt(0).toUpperCase() +
                            data.name.slice(1)
                          : ""}
                      </p>
                      <div className="w-[100%] mt-1 text-[#71727A] text-base text-start text-ellipsis overflow-hidden whitespace-nowrap">
                        <span>
                          {data.plotnumber ? data.plotnumber + ", " : ""}
                          {data.address ? data.address + ", " : ""}
                          {data.city ? data.city : ""}
                        </span>
                      </div>
                      <div className=" mt-4">
                        <button className=" border-primary border  p-3 rounded-xl text-primary w-full font-semibold text-xs">
                          Locate Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default Chipt_Show_Vendor;
