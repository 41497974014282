import React from "react";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();

  const handleDoneClick = () => {
    navigate("/wallet");
  };

  return (
    <div className="flex flex-col items-center p-5">
      <div className="h-[117px] flex flex-col justify-center items-center">
        <h2 className="text-base leading-4 font-black text-center">
          Enjoy your drink!
        </h2>
        <p className="mt-[10px] text-[#71727A] leading-5 font-normal text-center">
          Remember to return your cup.
        </p>
      </div>
      <div className="mt-5 flex justify-center items-center">
        <div className="h-[222px] w-[222px] bg-[#6FBAFF] rounded-full flex justify-center items-center">
          <img
            src="/assets/svg/right/RightButton.svg"
            alt=""
            className="h-24 w-[130px]"
          />
        </div>
      </div>
      <div
        className="py-4 mt-10 w-full flex justify-center"
        onClick={handleDoneClick}
      >
        <button className="custom-button">Done</button>
      </div>
      <p className="mt-4 text-[#71727A] text-sm text-center">
        Credits will be credited within 5 minutes after purchase.
      </p>
    </div>
  );
};

export default PaymentSuccess;
