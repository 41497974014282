import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { FaStar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { state_Customer as selectCustomer } from "../../redux/authentication/CustomerAuthSlice";
import {
  selectCustomerId,
  setCouponCode,
  setCustomerId,
  setPackageId,
} from "../../redux/PaymentSlice/StripeSlice";
import "./PackageMain.css";
import styles from "./Wallet.module.css";

const PackagesMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customer = useSelector(selectCustomer);
  const customerId = useSelector(selectCustomerId);
  const [packages, setPackages] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [membership, setMembership] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  const [couponApplied, setCouponApplied] = useState(false);
  const [applyingCoupon, setApplyingCoupon] = useState(false);

  useEffect(() => {
    const fetchPackages = async () => {
      if (loading) {
        return;
      }
      try {
        setIsLoading(true);
        const response = await axios.get("/api/v1/customer/package");
        if (
          response.data &&
          response.data.success &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          setPackages(response.data.data);
          setSelectedPackage(response.data.data[0]);
          dispatch(setPackageId(response.data.data[0]._id));
          if (response.data.membership) {
            setMembership(response.data.membership);
          }
        }
      } catch (error) {
        console.error("Error fetching packages:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPackages();

    if (!customerId && customer) {
      dispatch(setCustomerId(customer._id));
    }
  }, [customerId, customer, dispatch]);

  const handleCardClick = (pkg) => {
    if (selectedPackage && selectedPackage._id === pkg._id) {
      return;
    }
    setSelectedPackage(pkg);
    dispatch(setPackageId(pkg._id));
    // Remove First15 coupon if the new package price is less than 15
    if (coupon === "FIRST15" && pkg.price <= 15) {
      setCoupon("");
      setDiscount(0);
      setCouponApplied(false);
      toast.error("Coupon code is invalid as package price is less than 15");
      dispatch(setCouponCode(""));
    }
  };

  const handleCouponChange = (e) => {
    setCoupon(e.target.value);
  };

  const applyCoupon = async () => {
    setApplyingCoupon(true);
    try {
      const response = await axios.post(
        "/api/v1/customer/package/check-coupon-usage",
        {
          customerId,
          couponCode: coupon,
        }
      );

      if (response.data.hasUsedCoupon) {
        toast.error(response.data.message);
        setApplyingCoupon(false);
        return;
      }

      if (coupon === "DISCOUNT100") {
        setDiscount(1); // 100% off
        toast.success("Coupon code applied successfully");
        setCouponApplied(true);
      } else if (coupon === "FIRST15") {
        if (selectedPackage && selectedPackage.price > 15) {
          setDiscount(15); // 15 flat discount
          toast.success("Coupon code applied successfully");
          setCouponApplied(true);
        } else {
          toast.error(
            "Coupon code is invalid as package price is less than 15"
          );
        }
      } else {
        setDiscount(0);
        toast.error("Coupon code is invalid");
      }
      dispatch(setCouponCode(coupon));
    } catch (error) {
      console.error("Error applying coupon:", error);
      toast.error("Failed to apply coupon");
    } finally {
      setApplyingCoupon(false);
    }
  };

  const removeCoupon = () => {
    setCoupon("");
    setDiscount(0);
    setCouponApplied(false);
    toast.success("Coupon code removed");
    dispatch(setCouponCode(""));
  };

  const proceedToPayment = () => {
    navigate("/payment");
  };

  return (
    <div className="flex flex-col h-full relative rounded-md justify-start mt-6 px-6 pb-8 bg-white">
      <div className="py-4 px-4 mt-8">
        <h1 className="text-2xl font-extrabold">
          Purchase your <br /> suitable plan
        </h1>
        <p className="mt-4 text-sm text-[#71727A]">And get free cup credits</p>
      </div>
      {loading ? (
        <div className="flex justify-center items-center mt-5">
          <p>Loading...</p>
        </div>
      ) : packages.length === 0 ? (
        <div className="flex justify-center items-center mt-5">
          <p>No packages found</p>
        </div>
      ) : (
        <div
          className={`mt-5 w-full flex gap-3 flex-col ${styles.customScrollbar}`}
        >
          {packages.map((pkg, index) => (
            <div
              key={pkg._id}
              className={`card border cursor-pointer border-bordercolor flex relative justify-between items-center flex-row-reverse ${
                selectedPackage && selectedPackage._id === pkg._id
                  ? "selected-card"
                  : ""
              }`}
              onClick={() => handleCardClick(pkg)}
            >
              {index === 0 && (
                <div className="h-6 w-6 rounded-full bg-primary text-sm flex justify-center items-center absolute -top-3 -right-3">
                  <FaStar color="white" />
                </div>
              )}
              <div>
                <div className="text-base font-extrabold text-nowrap">
                  RM {pkg.price.toFixed(2)}
                </div>
                <div className="mt-[2px] text-xs leading-[14px] !text-nowrap text-black">
                  Use anytime
                </div>
              </div>
              <div className="flex gap-3 items-center">
                <div className="flex items-center">
                  <input
                    checked={selectedPackage && selectedPackage._id === pkg._id}
                    id={`default-radio-${pkg._id}`}
                    type="radio"
                    value=""
                    name="default-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    readOnly
                  />
                  <label
                    htmlFor={`default-radio-${pkg._id}`}
                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  ></label>
                </div>
                <ul className="listed max-sm:!items-center mx-auto w-full">
                  <li>
                    <span className="text-sm font-extrabold leading-[16.94px]">
                      {pkg.totalCredits} Credits
                    </span>
                  </li>
                  {pkg.freeCupCredits > 0 && (
                    <li>
                      <span className="mt-1 text-xs text-primary font-medium">
                        +{pkg.freeCupCredits} Free Credits
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="mt-8 rounded-2xl bg-[#F8F9FE] p-6">
        <h1 className="text-base font-black leading-[19.36px]">
          You'll be charged:
        </h1>
        {selectedPackage && (
          <>
            <div className="flex items-center gap-3 mt-4">
              <h2 className="h-[10px] w-[10px] text-[10px]">
                <FaStar color="#006FFD" />
              </h2>
              <p className="text-xs font-normal text-[#71727A] leading-4">
                RM {(selectedPackage.price - discount).toFixed(2)} -{" "}
                {selectedPackage.totalCredits} Credits +{" "}
                {selectedPackage.freeCupCredits} Free
              </p>
            </div>
            {!membership && (
              <div className="flex items-center gap-3 mt-4">
                <h2 className="h-[10px] w-[10px] text-[10px]">
                  <FaStar color="#006FFD" />
                </h2>
                <p className="text-xs font-normal text-[#71727A] leading-4">
                  Membership Fee - RM15.00 (Fully refundable anytime)
                </p>
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex flex-col my-4">
        <input
          type="text"
          placeholder="Enter coupon code"
          value={coupon}
          onChange={handleCouponChange}
          className="p-2 border rounded"
          disabled={couponApplied}
        />
        {couponApplied ? (
          <button
            className="custom-button mt-2"
            style={{
              backgroundColor: "#8B0000",
              borderColor: "#8B0000",
            }}
            onClick={removeCoupon}
          >
            Remove Coupon
          </button>
        ) : (
          <button
            className="custom-button mt-2"
            onClick={applyCoupon}
            disabled={applyingCoupon}
          >
            {applyingCoupon ? "Applying Coupon..." : "Apply Coupon"}
          </button>
        )}
      </div>
      {!loading && (
        <div className="mt-auto pb-14">
          <button className="custom-button" onClick={proceedToPayment}>
            Proceed to Payment
          </button>
        </div>
      )}
    </div>
  );
};

export default PackagesMain;
