import Cookies from "js-cookie";
import React, { useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { IoMdNotifications, IoMdNotificationsOff } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useToggle, useVibrate } from "react-use";
import { ActionsContext } from "../../context/Context";
import { state_Is_Active } from "../../redux/authentication/AccountStatusSlice";
import {
  Call_Log_Out,
  resetCustomerAuthState,
  state_Has_Error,
  state_Is_Authenticated,
} from "../../redux/authentication/CustomerAuthSlice";
import { resetScannedCupState } from "../../redux/orders/ScannedCupSlice";
import { resetStripeState } from "../../redux/PaymentSlice/StripeSlice";
import { Call_Clear_Notification_Data } from "../../redux/Profile/CustomerNotificationSlice";
import { Call_Clear_Profile_Image_Data } from "../../redux/Profile/CustomerProfileImageSlice";
import { Call_Clear_Profile_Data } from "../../redux/Profile/CustomerProfileSlice";
import InAppNotification from "../NotificationComponents/InAppNotification";
import NavHeaderCollapse from "./Chipt_Collapse_Nav";

const Chipt_Nav_Bar = () => {
  const sideNavBar = useContext(ActionsContext);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state_Is_Authenticated);
  const isAccountActive = useSelector(state_Is_Active);
  const has_Error = useSelector(state_Has_Error);

  const [vibrating, toggleVibrating] = useToggle(false);
  useVibrate(vibrating, [100], false);

  // Array of routes to hide the buttons on
  const hiddenRoutes = ["/signup", "/signin", "/otpverify"];

  const handleHomeNavigation = (e) => {
    e.preventDefault();
    sideNavBar.setActiveNavBar(false);
    sideNavBar.setNotiNavBar(false);
    setTimeout(() => {
      if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
    }, 0);
    setTimeout(() => {
      if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
      navigate("/home");
    }, 100);
  };

  const handleNavigationRender = (e) => {
    e.preventDefault();
    sideNavBar.setActiveNavBar(!sideNavBar.activeNavBar);
    sideNavBar.setNotiNavBar(false);
    setTimeout(() => {
      if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
    }, 0);
    setTimeout(() => {
      if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
    }, 100);
  };

  const handleNotificationRender = (e) => {
    e.preventDefault();
    if (isAuthenticated) {
      if (typeof isAccountActive === "boolean" && !isAccountActive) {
        toast.error("Your account is not active yet.", { autoClose: 5000 });
        return;
      }
    }
    sideNavBar.setNotiNavBar(!sideNavBar.notiNavBar);
    sideNavBar.setActiveNavBar(false);
    setTimeout(() => {
      if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
    }, 0);
    setTimeout(() => {
      if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
    }, 100);
  };

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      sideNavBar.setActiveNavBar(false);
      if (isAuthenticated) {
        dispatch(Call_Log_Out()).then(() => {
          Cookies.remove("token");
          dispatch(Call_Clear_Profile_Image_Data());
          dispatch(Call_Clear_Profile_Data());
          dispatch(Call_Clear_Notification_Data());
          dispatch(resetStripeState());
          dispatch(resetCustomerAuthState());
          dispatch(resetScannedCupState());
          toast.success("You are logged out");
          navigate("/auth");
        });
      }
    }
  };

  useEffect(() => {
    sideNavBar.setActiveNavBar(false);
  }, [isAuthenticated, has_Error, dispatch]);

  useEffect(() => {
    sideNavBar.setActiveNavBar(false);
    sideNavBar.setNotiNavBar(false);
  }, [location]);

  return (
    <>
      {/* Main container start */}
      <div
        className={`w-[100%] h-auto flex items-center justify-center bg-navBar-cbg`}
      >
        {/* Sub Container start */}
        <div className="w-[100%] h-auto">
          {/* Project logo navigation */}
          <div className="w-full px-4 py-3 mx-auto flex items-center justify-between">
            <div className="flex items-center justify-start">
              <img
                className="w-14 object-cover h-14 cursor-pointer"
                src="/assets/images/logo/Chiptlogoblack.png"
                alt="Chipt_Logo"
                onClick={handleHomeNavigation}
              />
            </div>

            {/* Conditional rendering based on the route */}
            {!hiddenRoutes.includes(location.pathname) && (
              <div className="float-right flex items-center gap-2">
                <div className="flex items-center">
                  {isAuthenticated ? (
                    <button
                      className="text-gold hover:text-gold-dark"
                      onClick={handleNotificationRender}
                    >
                      {sideNavBar.notiNavBar ? (
                        <IoMdNotificationsOff className="text-2xl" />
                      ) : (
                        <IoMdNotifications className="text-2xl" />
                      )}
                    </button>
                  ) : null}
                </div>
                <div className="flex items-center">
                  <button onClick={handleNavigationRender}>
                    {sideNavBar.activeNavBar ? (
                      <AiOutlineMenuFold color="#006FFD" className="text-2xl" />
                    ) : (
                      <AiOutlineMenuUnfold
                        color="#006FFD"
                        className="text-2xl"
                      />
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Content Start */}
          <div className="w-[100%] h-auto float-left">
            {sideNavBar.notiNavBar && <InAppNotification />}
          </div>

          <div className="w-full px-5 mx-auto h-auto float-left">
            {sideNavBar.activeNavBar && (
              <NavHeaderCollapse logoutFun={handleLogout} />
            )}
          </div>
          {/* Content end */}
        </div>
        {/* Sub Container end */}
      </div>
      {/* Main container end */}
    </>
  );
};

export default Chipt_Nav_Bar;
