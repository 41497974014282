import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { RiHandCoinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToggle, useVibrate } from "react-use";
import { state_Vendors } from "../../redux/authentication/AllVendorSlice";
import {
  Call_Scanned_Cup_Details,
  removeVendorId,
  state_Cup_Details,
  state_Is_Loading,
  state_Vendor_Id,
} from "../../redux/orders/ScannedCupSlice";
import { Call_Customer_Cup_Return_To_Vendor } from "../../redux/transactions/ReturnCupSlice";
import CupDetails from "../asset/cupDetails.svg";
import Project_Loader from "../NavigationComponents/Project_Loader";

const ReturnCupPreview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(state_Is_Loading);
  const [returnLoading, setReturnLoading] = useState(false);
  const state_cupDetail = useSelector(state_Cup_Details);
  const stateVendors = useSelector(state_Vendors);
  const vendorIdFromState = useSelector(state_Vendor_Id);
  const [cupDetails, setCupDetails] = useState();
  const [vendors, setVendors] = useState();
  const [vendorID, setVendorID] = useState(vendorIdFromState || "");
  const [selectedVendor, setSelectedVendor] = useState();

  // Vibration
  const [vibrating, toggleVibrating] = useToggle(false);
  useVibrate(vibrating, [10], false);

  useEffect(() => {
    if (!vendorIdFromState) {
      navigate("/");
      toast.error("Please scan shop QR code to return cup");
    }
  }, [navigate, vendorIdFromState]);

  const handleVendorChange = (e) => {
    e.preventDefault();
    for (let i = 0; i < vendors.length; i++) {
      if (vendors[i]._id.toString() === e.target.value.toString()) {
        setSelectedVendor(vendors[i]);
        return;
      } else {
        setSelectedVendor(null);
      }
    }
    setVendorID(e.target.value);
  };

  // New Order Placement
  const handleProceedNowReturn = (e) => {
    e.preventDefault();
    dispatch(removeVendorId());
    let returnTime =
      new Date(cupDetails.orderDate).getTime() +
      cupDetails.returnDate * 86400000;
    let isCupCanBeReturn = returnTime > Date.now();
    if (!cupDetails.isOrderable && !cupDetails.isReturn && !isCupCanBeReturn) {
      toast.error(`Return timeSlot already passed so no return can be done!`);
      return;
    }
    if (!selectedVendor) {
      toast.error(`Please select a vendor`);
      return;
    }

    const temp = {
      modelID: cupDetails.modelID,
      uniqueID: cupDetails.uniqueID,
      vendorID: selectedVendor._id,
    };
    // Return API call
    setReturnLoading(true);
    setTimeout(() => {
      toggleVibrating();
    }, 0);
    setTimeout(() => {
      toggleVibrating();
      dispatch(Call_Customer_Cup_Return_To_Vendor(temp)).then((data) => {
        if (data.payload && data.payload.success) {
          toast.success(`Cup returned successfully!`);
          setReturnLoading(false);
          navigate("/home");
        } else if (data.payload && !data.payload.success) {
          toast.success(data.payload.message);
          setReturnLoading(false);
        } else {
          toast.error(`Cup not returnable!`);
          setReturnLoading(false);
        }
      });
    }, 100);
  };

  useEffect(() => {
    if (!state_cupDetail && !cupDetails) {
      dispatch(
        Call_Scanned_Cup_Details({
          modelID: cupDetails.modelID,
          uniqueID: cupDetails.uniqueID,
        })
      );
    } else if (state_cupDetail && !cupDetails) {
      setCupDetails(state_cupDetail.cupData);
    }
    setVendors(stateVendors.vendors);
  }, [isLoading, dispatch]);

  useEffect(() => {
    if (vendorIdFromState && vendors) {
      const vendor = vendors.find((v) => v._id === vendorIdFromState);
      if (vendor) {
        setSelectedVendor(vendor);
      }
    }
  }, [vendorIdFromState, vendors]);

  return (
    <>
      {returnLoading || isLoading ? (
        <div className="w-[100] h-screen">
          <Project_Loader />
        </div>
      ) : (
        <>
          <div className="w-[100%] lg:w-[90%] lg:mx-[5%] min-h-screen h-auto float-left overflow-auto">
            <div className="w-[100%] h-[5vh] flex items-center justify-center">
              <p className="text-2xl font-bold">Return Cup Preview</p>
            </div>

            {/* Cup Details */}
            <div className="w-[98%] min-h-[18rem] h-auto mx-[1%] my-4 bg-white float-left border border-gray-light rounded-lg shadow overflow-auto">
              {/* Cup */}
              <div className="w-[100%] min-h-[8rem] h-auto float-left overflow-auto my-6">
                <div className="w-[40%] min-h-[8rem] h-auto float-left">
                  <div className="w-[100%] min-h-[7rem] h-auto float-left flex items-center justify-center">
                    <img
                      className="w-[7rem] h-[7rem] rounded-sm"
                      src={
                        cupDetails?.cupImages[0]
                          ? cupDetails?.cupImages[0].url
                          : CupDetails
                      }
                      alt="cup_image"
                    />
                  </div>
                </div>

                <div className="w-[60%] min-h-[7rem] h-auto float-left px-2">
                  <div className="w-[100%] h-auto font-bold flex flex-wrap items-center justify-start">
                    <p className="w-[100%] text-3xl truncate overflow-hidden">
                      {cupDetails?.cupType && !isLoading
                        ? cupDetails.cupType
                        : ""}
                    </p>
                    <hr className="w-[70%] h-[2px] bg-navy text-navy rounded-full" />
                  </div>

                  <div className="w-[100%] h-auto font-bold flex items-end justify-start my-1">
                    <p className="w-[100%] text-md truncate overflow-hidden">
                      {cupDetails?.cupSize ? cupDetails.cupSize : ""}&nbsp;
                      <i className="font-medium">
                        &#10098;
                        {cupDetails?.capacity
                          ? cupDetails.capacity + " ml"
                          : ""}
                        &#10099;
                      </i>
                    </p>
                  </div>

                  <div className="w-[100%] font-bold flex items-end justify-start">
                    <div className="w-[100%] flex items-center justify-start overflow-hidden">
                      <span className="text-sm">
                        {cupDetails?.currency
                          ? cupDetails.currency.toUpperCase()
                          : ""}
                        &nbsp;{cupDetails?.price ? cupDetails.price : ""}
                      </span>
                      <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                      <span className="text-sm flex items-center justify-center">
                        <RiHandCoinLine className="text-md text-gold" />
                        &nbsp;{cupDetails?.point ? cupDetails.point : 0}
                      </span>
                    </div>
                  </div>

                  <div className="w-[100] text-[0.65rem] flex items-end justify-start">
                    <p className="w-[100%] float-left overflow-hidden">
                      <i className="w-[25%] float-left">Model ID:</i>
                      <span className="w-[75%] float-left truncate overflow-hidden">
                        {cupDetails?.modelID ? cupDetails.modelID : ""}
                      </span>
                    </p>
                  </div>

                  <div className="w-[100] text-[0.65rem] flex items-end justify-start">
                    <p className="w-[100%] float-left overflow-hidden">
                      <i className="w-[25%] float-left">Unique ID:</i>
                      <span className="w-[75%] float-left truncate overflow-hidden">
                        {cupDetails?.uniqueID ? cupDetails.uniqueID : ""}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              {/* Separator */}
              <hr className="w-[88%] mx-[6%] h bg-black my-4" />

              {/* Vendor Details */}

              <div className="w-[100%] min-h-[8rem] h-auto float-left overflow-auto my-4">
                {selectedVendor ? (
                  <>
                    <div className="w-[40%] min-h-[8rem] h-auto float-left">
                      <div className="w-[100%] min-h-[7rem] h-auto float-left flex items-center justify-center">
                        <img
                          className="w-[7rem] h-[7rem] rounded-sm"
                          src={selectedVendor.profilePicture.url}
                          alt="vendor_image"
                        />
                      </div>
                    </div>

                    <div className="w-[60%] min-h-[7rem] h-auto float-left px-2">
                      <div className="w-[100%] h-[2rem] font-bold flex flex-wrap items-center justify-start">
                        <p className="w-[100%] text-3xl truncate overflow-hidden">
                          {selectedVendor.name.charAt(0).toUpperCase() +
                            selectedVendor.name.slice(1)}
                        </p>
                        <hr className="w-[70%] h-[2px] bg-navy text-navy rounded-full" />
                      </div>

                      {selectedVendor.distance && (
                        <div className="w-[100%] h-auto flex items-end justify-start mt-4">
                          <p className="w-[100%] h-auto text-xs font-semibold break-words overflow-hidden">
                            {selectedVendor.distance} away from you.
                          </p>
                        </div>
                      )}

                      <div className="w-[100%] h-auto flex items-end justify-start mt-4">
                        <p className="w-[100%] h-[5rem]  text-xs break-words overflow-hidden">
                          {selectedVendor.plotnumber +
                            " " +
                            selectedVendor.address +
                            " " +
                            selectedVendor.city +
                            " " +
                            selectedVendor.state +
                            ", " +
                            selectedVendor.country +
                            " " +
                            selectedVendor.zipCode}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="w-[100%] min-h-[8rem] h-auto flex items-center justify-center">
                    No Vendor selected
                  </div>
                )}
              </div>
            </div>

            {/* Select Vendor */}
            <div className="w-[98%] h-[2.5rem] mx-[1%] bg-white flex items-center justify-center border border-gray-light rounded-2xl overflow-hidden">
              <div className="w-[100%] h-[2.5rem] flex items-center justify-center overflow-hidden">
                <p className="w-[40%] h-full text-sm font-medium flex items-center justify-start bg-gray-light px-4">
                  Select vendor
                </p>
                <select
                  value={vendorID}
                  onChange={handleVendorChange}
                  className="w-[60%] h-[2.5rem] rounded-lg bg-white text-sm px-2 outline-none appearance-none"
                  placeholder=""
                  disabled={!!vendorIdFromState}
                >
                  <option className="text-gray">
                    Please choose one option
                  </option>
                  {vendors ? (
                    <>
                      {vendors.map((data) => {
                        let isDistance = data.distance ? true : false;
                        return (
                          <option key={data._id} value={data._id}>
                            {isDistance ? (
                              <>
                                {data.name.charAt(0).toUpperCase() +
                                  data.name.slice(1)}{" "}
                                {"   (" + data.distance + " away)"}
                              </>
                            ) : (
                              <>
                                {data.name.charAt(0).toUpperCase() +
                                  data.name.slice(1)}
                              </>
                            )}
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            </div>
            <div className="w-[100%] h-auto md:-ml-[15%] lg:-ml-[25%] fixed bottom-0 py-2 flex items-center justify-center">
              <button
                className="px-8 py-1 text-lg font-bold text-white bg-primaryBlue hover:bg-primaryBlueHover rounded-md"
                onClick={handleProceedNowReturn}
              >
                Return
              </button>
            </div>
          </div>
        </>
      )}
      <Helmet>
        <title>CHIPT | RETURN PREVIEW</title>
      </Helmet>
    </>
  );
};

export default ReturnCupPreview;
